import React from 'react';
import ResourceList from '../components/ResourceEntryList';

function Resources() {
  return (
    <main>
      <div className="resources-head">
        <img src="http://www.2code.com.mx/static/media/chuck.png" alt="2code.com.mx"  />
      </div>
      <div className="working-area blog">
        <ResourceList></ResourceList>
      </div>
    </main>
  )
}

export default Resources;
