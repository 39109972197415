/**
 * site: 2code.com.mx
 * component: index
 * 
 * @author: Salvador G. N.
 * 
 * History:
 * #Change  date            Description
 * 1        15 August 2020  Initial version
 */

import App from './App';

import { HashRouter as Router} from 'react-router-dom';

import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

