/**
 * site: 2code.com.mx
 * component: Home
 * 
 * @author: Salvador G. N.
 * 
 * History:
 * #Change  date            Description
 * 1        15 August 2020  Initial version
 */

import BlogEntryList from '../components/BlogEntryList';

import { FaGithubSquare } from 'react-icons/fa';

import React from 'react';

/**
 * This component will be shown within the main 
 * page http://www.2code.com.mx/ 
 * 
 * It shows the presentation page and a list 
 * of the lastest 3 blog entries.
 * 
 */
function Home() {
  return (
    <article className="working-area">
      <main>
        <div className="container">
          <div className="row mt-3 mb-5">
            <div className="col-md-8 mt-3">
              <h3>
                Fomentando la <span className="text-warning">cultura</span> de compartir <span className="text-warning">conocimiento.</span>
              </h3>
              <p className="text-justify mt-5">
                En este sitio puedes encontrar artículos relacionados con:
                <span className="text-primary"> Java, JS, Angular, Node.js, React.js, AWS, Azure, DevOps y Herramientas</span> que mejoran la productividad del desarrollo de aplicaciones.
              </p>
              <p className="text-justify mt-5">
                <FaGithubSquare className="ln-icon" />Todo código generado en nuestras publicaciones esta disponible de manera pública en Github.
              </p>
              <p className="text-justify mt-3">
                <img src="https://www.2code.com.mx/static/media/netlify-512.png" className="ln-icon" alt="netlify"/>Y usamos Netlify para algunos live-demos.
              </p>
            </div>
            <aside className="col-md-4 mt-3 border-left">
              <h3 className="aside-subtitle">
                Últimas entradas
              </h3>
              <div className="mt-3"> 
                <BlogEntryList size={3}/>
              </div>
            </aside>
          </div>
        </div>
      </main>
    </article>
  )
}

export default Home;
