
import { client } from '../aggregators/Contentful';

import { Link } from 'react-router-dom';
import React from 'react';

function getBlogList(blogs, size) {

  const firtsLogs = blogs.slice(0, blogs.length < size ? blogs.length : size);

  return firtsLogs.map(blog => {
    const linkTo = "/blog/" + blog.sys.id;
    return (
      <article key={blog.sys.id} className="card blog-item mb-3 mt-3">
        <img className="card-img-top" src={blog.fields.thumbBlogImage.fields.file.url} alt={blog.fields.title} />
        <section className="card-body">
          <Link to={linkTo}>
            <p className="card-text">{blog.fields.title}</p>
          </Link>
        </section>
      </article>
    )
  })
}

function BlogEntryList({ size }) {

  const [blogList, setBlogList] = React.useState([]);

  React.useEffect(() => {
    client.getEntries({
      select: "sys.id,fields.author,fields.title,fields.thumbBlogImage",
      content_type: "codeComMxBlog"
    })
      .then(response => { setBlogList(response.items) })
      .catch(error => console.log('error', error))
  }, []);

  if (blogList === []) {
    return <div />
  }

  // console.log('blogList', blogList);

  return (
    <div className="blogList">
      {getBlogList(blogList, size)}
    </div>
  )
}

export default BlogEntryList;
