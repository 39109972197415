/**
 * site: 2code.com.mx
 * component: About
 * 
 * @author: Salvador G. N.
 * 
 * History:
 * #Change  date            Description
 * 1        15 August 2020  Initial version
 */

import { FaJava, FaGithubSquare, FaJenkins, FaAws, FaReact, FaAngular, FaGuitar, FaLinkedin, FaMicrosoft, FaYoutube } from 'react-icons/fa';
import { RiStarSmileLine } from 'react-icons/ri';
import { GrDocker } from 'react-icons/gr';
import { DiScrum } from 'react-icons/di';
import { GiBasketballJersey, GiBookshelf } from 'react-icons/gi';
import { MdLocalMovies } from 'react-icons/md';
import { BsMusicPlayerFill } from 'react-icons/bs';

import React from 'react';

/**
 * Function with a list of technologies 
 * that I'm interested 
 */
const technologies = () => {

  return (
    <section className="card-body">
      <h5 className="card-title">Principales tecnologías de interés:</h5>
      <ul className="mt-3 tech-items">
        <li><FaJava /> Java</li>
        <li><FaJava /> Spring</li>
        <li><FaJava /> Quarkus</li>
        <li><FaReact /> React</li>
        <li><FaAngular/> Angular</li>
        <li><FaAws /> AWS</li>        
        <li><FaMicrosoft /> Azure, Azure DevOps</li>        
        <li><GrDocker /> Dockers, Kubernetes, Openshift</li>
        <li><FaJenkins /> DevOps tools (Jenkins, Jira, Confluence, SonarQube, Maven, Artifactory)</li>
      </ul>
    </section>)
};

/**
 * Function with a list of certifications achieved
 */
const certifications = () => {
  return (
    <section className="card-body">
      <h5 className="card-title">Certificaciones</h5>
      <ul className="mt-3 tech-items">
        <li><FaJava /> Oracle Certified Associate, Java SE 8 Programmer</li>
        <li><DiScrum /> Scrum Certification for Java Developer</li>
        <li><DiScrum /> Scrum Master Accredited Certification</li>
        <li><FaAws /> AWS Certified Cloud Practitioner</li>
        <li><FaMicrosoft /> Microsoft Certified: Azure Fundamentals</li>
        <li><FaMicrosoft /> Microsoft Certified: Azure Data Fundamentals</li>
        <li><FaMicrosoft /> Microsoft Certified: Azure AI Fundamentals</li>
      </ul>
    </section>)
};

/**
 * Function with a list of hobbies
 */
const interests = () => {
  return (
    <section className="card-body">
      <h5 className="card-title">Hobbies</h5>
      <ul className="mt-3 tech-items">
        <li><GiBasketballJersey /> Basketball</li>
        <li><FaGuitar /> Guitarra Acústica, Guitarra Eléctrica, Bajo Eléctrico</li>
        <li><BsMusicPlayerFill /> Música de los 80's en inglés</li>
        <li><MdLocalMovies /> Películas y anime (All kind)</li>
        <li><GiBookshelf /> Lectura (Libros favoritos: Fahrenheit 451, 1984, Rebelión en la granja, Un mundo Feliz, El anticristo, Más allá del bien y mal)</li>
      </ul>
    </section>)
};

/**
 * Function with a link to my LinkedIn profile
 */
const resume = () => {
  return (
    <section className="card-body">
      <h5 className="card-title">Curriculum</h5>
      <a href="https://www.linkedin.com/in/salvador-gonzález-nolasco-4138a350"
        target="_blank" rel="noopener noreferrer" >
        <FaLinkedin /> LinkedIn Profile
        </a>
    </section>)
};

const channel = () => {
  return (
    <section className="card-body">
      <h5 className="card-title">Channel</h5>
      <a href="https://www.youtube.com/channel/UCdQTqexTD4PUxnqQGuK59xA"
        target="_blank" rel="noopener noreferrer" className="channel" >
        <FaYoutube /> Youtube Channel
        </a>
    </section>)  
}

/**
 * Functional component resposible to renders the full information about me
 * manage state and select information that whill be shown 
 */
function About() {

  const [moreInformation, setMoreInformation] = React.useState(technologies());

  const handleOnClick = (option) => {
    switch (option) {
      case 2:
        setMoreInformation(certifications());
        break;
      case 3:
        setMoreInformation(interests());
        break;
      case 4:
        setMoreInformation(resume());
        break;
      case 5:
        setMoreInformation(channel());
        break;
      default:
        setMoreInformation(technologies());
        break;
    }
  };


  /**
   * Main render function
   */
  return (
    <main className="working-area-50 mt-5 mb-5 card">
      <img className="card-img-top" src="http://www.2code.com.mx/static/media/about_me.jpg" alt="El shava" />
      <div className="card-body">
        <h5 className="card-title">"El Shava"</h5>
        <p className="card-text">
          Apasionado por el desarrollo de software y la música.
        </p>
        <div className="card-body float-right">
          <button type="button" className="navbar-btn aboutlink ml-2" onClick={() => handleOnClick(1)}><FaGithubSquare /></button>
          <button type="button" className="navbar-btn aboutlink ml-2" onClick={() => handleOnClick(2)}><RiStarSmileLine /></button>
          <button type="button" className="navbar-btn aboutlink ml-2" onClick={() => handleOnClick(3)}><FaGuitar /></button>
          <button type="button" className="navbar-btn aboutlink ml-2" onClick={() => handleOnClick(4)}><FaLinkedin /></button>
          <button type="button" className="navbar-btn aboutlink ml-2" onClick={() => handleOnClick(5)}><FaYoutube /></button>
        </div>
      </div>
      <hr />
      {moreInformation}
    </main>
  )
}

export default About;
