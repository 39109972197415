import React from 'react';

function Courses() {
  return (
    <div className="working-area">
      Courses
    </div>
  )
}

export default Courses;
