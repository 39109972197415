import BlogEntryList from '../components/BlogEntryList';
import React from 'react';


function Blog() {

  return (
    <main>
      <div className="blog-head">
        <img src="http://www.2code.com.mx/static/media/programming-mf.png" alt="2code.com.mx"  />
      </div>
      <div className="working-area blog">
        <BlogEntryList size={100}/>
      </div>
    </main>
  )
}

export default Blog;
