import { client } from '../aggregators/Contentful';

import React from 'react';


function getResourceList(resources) {
    return resources.map(
     resource => {
      return (
        <article key={resource.sys.id} className="card blog-item mb-3 mt-3">
          <img className="card-img-top" src={resource.fields.resourceImage.fields.file.url} alt="{resource.fields.resourceId}" />
          <section className="card-body">
            <p className="card-text text-primary fw-bold"><b>{resource.fields.resourceId}</b></p>
            <p className="card-text"><b>Descripción</b></p>
            <p className="card-text">{resource.fields.description}</p>
            <p className="card-text"><b>Enlaces</b></p>
            <p className="card-text">
              {resource.fields.fileName} - {resource.fields.size} - <a target="_blank" href={resource.fields.link}>LINK</a>
            </p>
            <p className="card-text"><b>Password: </b> {resource.fields.password}</p>
          </section>
        </article>        
      )
     }
    )
}

function ResourceList() {
    const [resourceList, setResourceList] = React.useState([]);
    const contentfulModel = "codeComMxResource";
    const contentfulQuery = 
    "sys.id,fields.resourceId,fields.resourceImage,fields.description,fields.fileName,fields.size,fields.link,fields.password";


    React.useEffect(() => {
        client.getEntries({
          select: contentfulQuery,
          content_type: contentfulModel
        })
          .then(response => { setResourceList(response.items) })
          .catch(error => console.log('error', error))
      }, []);
    
    if (resourceList === []) {
      return <div />
    }

    return (
      <div className="blogList">
        {getResourceList(resourceList)}
      </div>
    )
}


export default ResourceList;