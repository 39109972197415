
import { Link } from 'react-router-dom';

import { FaAlignRight } from 'react-icons/fa';

import React from 'react';


function Navbar() {

  const [showLinks, setShowLinks] = React.useState(false);

  const handleOnclickShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const handleOnclickLink = () => {
    setShowLinks(false);
  }

  return (
    <nav className="toppage">
      <div className="navbar working-area">
        <div className="logo">
          <Link to="/">
            <img src="http://www.2code.com.mx/static/media/main.png" alt="2code" />
          </Link>
        </div>
        <div className={showLinks ? "links show-links" : "links"}>
          <ul>
            <li><Link onClick={handleOnclickLink} to="/">Inicio</Link></li>
            <li><Link onClick={handleOnclickLink} to="/blog">Blog</Link></li>
            <li><Link onClick={handleOnclickLink} to="/resources">Recursos</Link></li>
            <li><Link onClick={handleOnclickLink} to="/about">About me</Link></li>
          </ul>
        </div>
        <div className="navbar-toggle">
          <button
            type="button"
            className="navbar-btn"
            onClick={handleOnclickShowLinks}
          >
            <FaAlignRight />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
