/**
 * site: 2code.com.mx
 * component: BlogEntry
 * 
 * @author: Salvador G. N.
 * 
 * History:
 * #Change  date            Description
 * 1        15 August 2020  Initial version
 */

import { client } from '../aggregators/Contentful';
import { useParams } from 'react-router-dom';

import React from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * This component consume information from 
 * contenful in order to get a related information of a blog entry
 */
function BlogEntry() {

  /**
   * Use State
   */
  const [source, setSource] = React.useState("##### Loading Markdown...");
  const [article, setAuthor] = React.useState(
    {
      title: "",
      author: "",
      img: "",
      date: "",
      tags: []
    }
  );

  /**
   * Route params from react-router-dom
   */  
  const { id } = useParams();

  /**
   * Use Effect when component is mounted and id blog 
   * is changed 
   */
  React.useEffect(() => {
    client.getEntry(id)
      .then(response => { 
        console.log('response', response); 
        setSource(response.fields.markdown);
        setAuthor({
          title: response.fields.title,
          author: response.fields.author,
          authorPicture: response.fields.authorImage.fields.file.url,
          img: response.fields.mainBlogImage.fields.file.url,
          date: response.fields.publicationDate,
          tags: response.fields.tags
        });
      })
      .catch(error => console.log('error', error));
  }, [id]);


  /**
   * It renders a blog entry base on the id provided by 
   * react-router-dom params
   */
  return (
    <div className="working-area-50 mt-5">
      <img src={article.img} alt={article.title} className="blog-img" />
      <h1 className="article-title mt-4">{article.title}</h1>
      <section className="article-author">
        <div className="text-right">
          <h6>{article.author}</h6>
          {article.date}
        </div>
        <img
          className="article-author-img"
          src={article.authorPicture}
          alt={article.author} />
      </section>
      <ReactMarkdown children={source} className="article-content mt-5 mb-5" />
    </div>
  )
}

export default BlogEntry;
