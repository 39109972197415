
import React from 'react';


function Error() {
  return (
    <div className="mt-5 text-center working-area">
      <h1>Page not found - 404</h1>
    </div>
  )
}

export default Error;
