
import { Link } from 'react-router-dom';
import React from 'react';

function BottomBar() {
  return (
    <div className="bottom">
      <nav>
        <Link to="/">Inicio</Link>
        <Link to="/courses">Cursos</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/resources">Recursos</Link>
        <Link to="/about">About me</Link>
      </nav>
      <p>2code.com.mx <br />2020</p>
    </div>
  )
}

export default BottomBar;
