/**
 * site: 2code.com.mx
 * component: App
 * 
 * @author: Salvador G. N.
 * 
 * History:
 * #Change  date            Description
 * 1        15 August 2020  Initial version
 */

import Home from './pages/Home';
import Courses from './pages/Courses';
import Blog from './pages/Blog';
import BlogEntry from './pages/BlogEntry';
import Resources from './pages/Resources';
import About from './pages/About';
import Error from './pages/Error';
import Navbar from './components/Navbar';
import BottomBar from './components/BottomBar';

import { Route, Switch } from 'react-router-dom';
import React from 'react';

/**
 * It renders the main navigation map 
 */
function App() {

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/courses" component={Courses}></Route>
        <Route exact path="/blog" component={Blog}></Route>
        <Route exact path="/blog/:id" component={BlogEntry}></Route>
        <Route exact path="/resources" component={Resources}></Route>
        <Route exact path="/about" component={About} />
        <Route component={Error}></Route>
      </Switch>
      <BottomBar/>
    </>
  )
}

export default App;
